import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import { PageHeader } from '../components/design';

import { AboutPageQuery } from '../../graphql-types';

interface AboutPageTemplateProps {
  title: string;
  subtitle: string;
  content: string;
  contentComponent?: React.ReactNode;
}

export const AboutPageTemplate: React.FC<AboutPageTemplateProps> = ({
  title,
  subtitle,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content; // fix typing

  return (
    <>
      <PageHeader title={title} subtitle={subtitle} />
      <section className="section bg-gray-100">
        <PageContent className="content" content={content} />
      </section>
    </>
  );
};

interface AboutPageProps {
  data: AboutPageQuery;
}

const AboutPage: React.FC<AboutPageProps> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        content={post.html}
      />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
      }
    }
  }
`;
